import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";

import { Link } from 'react-router-dom';

import ScrollspyNav from "./scrollSpy";

const NavbarPage = ({ navItems, ...props }) => {

  const [state, setState] = useState({
    isOpenMenu: false,
    navClass: ""
  })


  const toggle = () => {
    setState({ isOpenMenu: !state.isOpenMenu });
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);

    }
  }, [])




  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;

    if (scrollup > 50) {
      setState({ navClass: "is-sticky" });
    } else {
      setState({ navClass: "" });
    }
  }

  //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  let targetId = navItems.map((item) => {
    return item.idnm;
  });


  const addClass = props.className ? `${props.className}` : '';

  const logo = { img: "/img/pb_logo_white.svg", text: "PublicBacklog.com logo", url: "/" }

  return (
    <React.Fragment>
      <div id="navbar" className={state.navClass}>
        <nav
          className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
        >
          <Container>
            <NavbarBrand className="logo text-uppercase" href="/">
              <div className={` ${addClass}`}>
                {/* <Link to="/">
                  <img className="align-top me-2" style={{ height: '2.2rem' }} src={logo.img} alt="Logo" />
                  <span className="font-sans">Public Backlog</span>
                </Link> */}

                <Link to="/">
                  <h2 className="text-2xl font-semibold text-gray-200 h-10 max-h-10 pt-1 overflow-hidden hidden-compact">
                    <img className="align-top me-2" style={{ height: '2.2rem' }} src={logo.img} alt="Logo" />
                    <span className="font-sans" style={{ textTransform: 'none' }}>Public Backlog</span>
                  </h2>
                </Link>

                {/* <div className="">
                  <a href="/">
                    <h2 className="text-2xl font-semibold text-gray-200 h-10 max-h-10 pt-1 overflow-hidden hidden-compact">
                      <img className="inline-block ltr:mr-2 rtl:ml-2 -mt-1 h-10" src="/img/pb_logo.svg" />
                      <span className="text-gray-700 font-sans">Public Backlog</span>
                    </h2>
                  </a>
                </div> */}

              </div>

            </NavbarBrand>
            <NavbarToggler onClick={toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>

            <Collapse
              id="navbarCollapse"
              isOpen={state.isOpenMenu}
              navbar
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav navbar className="navbar-center" id="mySidenav">
                  {navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink href={"#" + item.idnm}>
                        {" "}
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                  <NavItem>
                    <NavLink target="_blank" href="https://docs.publicbacklog.com/">
                      Documentation
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="nav-button ms-auto">
                  <Nav navbar className="navbar-end">
                    <li>
                      <Button
                        color="none"
                        type="button"
                        className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                        onClick={() => window.location.href = "https://app.publicbacklog.com/user/login"}
                      >
                        Login
                      </Button>
                    </li>
                    <li className="">
                      <Button
                        color="none"
                        type="button"
                        className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                        onClick={() => window.location.href = "https://app.publicbacklog.com/user/login"}
                      >
                        Sign Up
                      </Button>
                    </li>
                  </Nav>
                </div>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </nav>
      </div>
    </React.Fragment>
  );

}

export default NavbarPage;
