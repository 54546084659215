import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

//Import Section Title
import SectionTitle from "../common/section-title";

const Features = () => {
  const initialValues = {
    name: '',
    email: '',
    subject: '',
    comments: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    subject: Yup.string().required('Subject is required'),
    comments: Yup.string().required('Message is required')
  });

  const onSubmit = (values, { setSubmitting }) => {
    console.log('Form data', values);
    setSubmitting(false);
    // Here you would usually send the form values to the server
    fetch('https://api.publicbacklog.com/messages/send-landing-page-message', {
      // fetch('http://localhost:5000/messages/send-landing-page-message', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.reload();
        }
        console.log(data);
      });
  };

  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          {/* section title */}
          <SectionTitle
            title="GET IN TOUCH"
            desc="Have a question? Contact us now and we'll be happy to assist you."
          />

          <Row >
            <Col lg={2}></Col>
            <Col lg={8}>
              <div className="custom-form mt-4 pt-4">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ isSubmitting }) => (
                    <Form name="myForm">
                      <p id="error-msg"></p>
                      <div id="simple-msg"></div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mt-2">
                            <Field name="name" type="text" className="form-control" placeholder="Your name*" />
                            <ErrorMessage name="name" component="span" className="validation-error-message" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mt-2">
                            <Field name="email" type="email" className="form-control" placeholder="Your email*" />
                            <ErrorMessage name="email" component="span" className="validation-error-message" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <Field name="subject" type="text" className="form-control" placeholder="Your Subject.." />
                            <ErrorMessage name="subject" component="span" className="validation-error-message" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <Field name="comments" as="textarea" rows="4" className="form-control" placeholder="Your message..." />
                            <ErrorMessage name="comments" component="span" className="validation-error-message" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button type="submit" id="submit" name="send" className="submitBnt btn btn-primary" disabled={isSubmitting}>
                            Send Message
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;

