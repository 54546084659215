import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Social = () => {
  return (
    <React.Fragment>
      <section className="contact-social bg-light">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <ul className="list-inline social mt-4">
                <li className="list-inline-item">
                  <Link to="#" className="social-icon">
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <a href="https://twitter.com/publicbacklog" className="social-icon">
                    <i className="mdi mdi-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <Link to="https://www.linkedin.com/company/publicbacklog/" className="social-icon">
                    <i className="mdi mdi-linkedin"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-icon">
                    <i className="mdi mdi-youtube"></i>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg={2} className="mt-4">
              {/* <p className="contact-title">
                <i className="pe-7s-call"></i> &nbsp;+91 123 4556 789
              </p> */}
            </Col>
            <Col lg={4} className="mt-4 text-end">
              <p className="contact-title">
                <i className="pe-7s-mail-open"></i>&nbsp; support@publicbacklog.com
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Social;
