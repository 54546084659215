import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Section = () => {
  const [state, setState] = useState({
    isOpen: false,
  });

  const callModal = () => {
    setState({ isOpen: true });
  };


  return (
    <React.Fragment>
      <section
        className="section bg-home home-half"
        id="home"
        data-image-src="images/bg-home.jpg"
      >
        <div className="bg-overlay"></div>
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="col-lg-8 offset-lg-2 text-white text-center"
                >
                  <h1 className="home-title">
                    We help you collect feedback from your customers
                  </h1>
                  <p className="pt-3 home-desc">
                    Transforming feedback into action, effortlessly.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="wave-effect wave-anim">
          <div className="waves-shape shape-one">
            <div className="wave wave-one">
              <svg
                width="2000"
                height="100"
                viewBox="0 0 2000 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M471 45C263 -22.2 71 18.6667 1 47.5V119H1999.5V34.5C1917.9 85.3 1823.83 68.3333 1787 53.5C1721.8 25.1 1603.17 9.33333 1552 4.99999C1366.4 -12.6 1172.67 34.3333 1099 60C1014.6 91.6 831.833 97.5 751 96.5C664.6 96.5 528.333 62.1667 471 45Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </div>
          </div>
          <div className="waves-shape shape-two">
            <div className="wave wave-two"></div>
          </div>
          <div className="waves-shape shape-three">
            <div className="wave wave-three"></div>
          </div>
        </div>

        {/* Render ModalSection Component for Modal */}
        {/* <ModalSection
          channel="vimeo"
          videoId="99025203"
          closeModal={closeModal}
          isOpen={state.isOpen}
        /> */}
      </section>
    </React.Fragment>
  );
};

export default Section;
