const fetchWrapper = () => {
    const apiUrl = "https://api.publicbacklog.com"
    function get(url, includeCredentials = true) {
        const requestOptions = {
            method: 'GET',
            headers: { 'url-origin': window.location.origin + window.location.pathname }
        };

        if (includeCredentials) {
            requestOptions.credentials = 'include';
        }

        return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse);
    }

    function post(url, body, includeCredentials = true) {
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'url-origin': window.location.origin + window.location.pathname },
            body: JSON.stringify(body)
        };

        if (includeCredentials) {
            requestOptions.credentials = 'include';
        }

        console.log('attempting get request', JSON.stringify(requestOptions), apiUrl);

        return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse);
    }

    function postForm(url, body, includeCredentials = true) {
        const requestOptions = {
            method: 'POST',
            body: body
        };

        if (includeCredentials) {
            requestOptions.credentials = 'include';
        }

        console.log('options', JSON.stringify(requestOptions));
        return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse);
    }

    function put(url, body) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };
        return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse);
    }

    // prefixed with underscored because delete is a reserved word in javascript
    function _delete(url) {
        const requestOptions = {
            method: 'DELETE'
        };
        return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse);
    }

    // helper functions

    function handleResponse(response) {
        const unauthorized = (response.status === 401 || response.status === 403);
        if (unauthorized && !window.location.toString().includes('/user/login')
            && !window.location.toString().includes('/user/register')
            && !window.location.toString().includes('/user/reset-password')
        ) {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let hasReauthenticated = params.get('hasReauthenticated');
            let locationUrl = `/user/login?returnUrl=${encodeURIComponent(window.location.pathname)}`;
            if (window.location.search !== '') {
                locationUrl += encodeURIComponent(window.location.search);
            }
            if (hasReauthenticated) {
                locationUrl += '&hasReauthenticated=true';
            }
            window.location = locationUrl;
            return { isAuthenticated: false };
        }

        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                const error =
                    (data && data.message) ||
                    response.statusText;
                console.log(`error in fetchWrapper: ${error}`);
                return Promise.reject(error);
            }

            return data;
        });
    }

    return {
        get,
        post,
        put,
        delete: _delete,
        postForm,
    }
}

export default fetchWrapper;

